<template>
  <Modal :title="title"
    :active="modalActive"
    :data="modalData"
    size="l"
    no-scroll
    @modal-close="modalActive=false"
    >
    <template v-slot:modal-body>
      <div class="text-wrapper">
        <p>{{ text }}</p>
        <div class="loader-wrapper"><Loader size="xs" :active="true"/></div>
      </div>

      <div class="progressModal-progress">
        <div class="progressModal-progress-bar">
          <div class="progressModal-progress-bar-line"
            :style="{
              width: `${modalData.progress}%`,
            }"></div>
          <div class="progressModal-progress-bar-number"
            :style="{
              left: `${ modalData.progress}%`,
            }">
            {{ modalData.progress }}%
          </div>
        </div>
      </div>

      <BlockCode v-if="displayCode" :code="modalData.data" />

    </template>
  </Modal>
</template>
<script>
import Loader from '@/components/layout/Loader.vue'
import Modal from '@/components/layout/Modal.vue'
import BlockCode from '@/components/base/BlockCode.vue'

export default {
  name: 'ProgressModal',
  components: {
    Loader,
    Modal,
    BlockCode,
  },
  props: {
    title: {
      type: String,
      default: 'Synchronisation de la notation',
    },
    text: {
      type: String,
      default: 'La notation est en cours de synchronisation.',
    },
    displayCode: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      modalData: {
        progress: 0,
      },
      modalActive: false,
    }
  },
  mounted() {
    this.emitter.on('progress-modal-open', this.openModal)
    this.emitter.on('progress-modal-close', this.closeModal)
    this.emitter.on('progress-modal-progress', this.setProgress)
    this.emitter.on('progress-modal-data', this.setData)
  },
  methods: {
    openModal() {
      this.modalData.progress = 0
      this.modalActive = true
    },
    closeModal() {
      this.modalActive = false
    },
    setProgress(data) {
      const total = (data.total === 0) ? data.current : data.total
      this.modalData.progress = Math.floor((100 * data.current) / total)
    },
    setData(data) {
      this.modalData.data = JSON.stringify(data)
    },
  },

}
</script>
<style lang="scss" scoped>

.text-wrapper{
  position: relative;
  display: flex;
  .loader-wrapper{
    position: relative;
    width: 25px;
    height: 25px;
    margin-left: 25px;
  }

}
.progressModal-progress {
  margin:  $gutter 0;
  width: 100%;
  display: flex;
  align-items: baseline;
  gap: $gutter-half;
}

.progressModal-progress-bar {
  position: relative;
  flex-grow: 1;
  height: 0.5rem;
  background-color: $color-gray-light;
  border-radius: 0.25rem;
}

.progressModal-progress-bar-line {
  height: 0.5rem;
  background-color: $color-primary-lighty;
  border-radius: 0.25rem;
}

.progressModal-progress-bar-number {
  position: absolute;
  bottom: $gutter-eighth;
  transform: translateX(-50%);
  font-size: $font-size-bigger;
  color: $color-primary-lighty;
  font-weight: $font-weight-black;
}

</style>
