<template>
  <div class="state-subview">
    <template v-if="editable && !$route.name.includes('Edit')">
      <teleport to="#header-action">
        <Btn v-if="helperService.userHasPermission('essaievenement_oedit')" class="action-btn" icon="create"
          color="white" hollow text="Modifier" :to="{
            name: 'essaiExperimentalTaskParameterEdit',
            params: {
              id: $route.params.id,
              tid: $route.params.tid
            }
          }" />

      </teleport>
    </template>
  </div>
  <div class="general-information-subview">
    <Section class="section--border-bottom bg-gray-lightestest">
      <Container>
        <Timeline :index="step" :total="states.length" :lastStepToRight="true" :stepsData="states" />
      </Container>
    </Section>
    <Section v-if="fetched && (noOrder || studiedVariable.length <= 0 || !essai?.plan)">
      <Container>
        <Alert :hasCloseBtn="false" type="warning">
          <p v-if="!essai?.plan">L'essai actuel ne dispose pas de plan.<br /> Pour consulter le calendrier, veuillez
            générer un plan.</p>
          <p v-if="noOrder">Aucun ordre de saisie n’est renseigné.</p>
          <p v-if="studiedVariable.length <= 0">Aucune variable n'a été ajouté.</p>
        </Alert>
      </Container>
    </Section>

    <Section>
      <Container>
        <template v-if="fetched && !noOrder && studiedVariable.length > 0 && essai?.plan">
          <NotationCard v-if="fetched" :reservation="reservation" :isOwner="owner" :isInDashboard="false" />
        </template>
      </Container>
    </Section>

    <Section v-if="fetched && error">
      <Container>
        <Alert :hasCloseBtn="false" type="error">
          {{ error }}
        </Alert>
      </Container>
    </Section>

  </div>
  <ProgressModal v-if="!error" title="Chargement de la reservation" text="Chargement en cours" :displayCode="false" />
</template>

<script>
import Section from '@/components/layout/Section.vue'
import Container from '@/components/layout/Container.vue'
import Timeline from '@/components/layout/Timeline.vue'
import NotationCard from '@/components/base/NotationCard.vue'
import Btn from '@/components/base/Btn.vue'
import Alert from '@/components/base/Alert.vue'
import ProgressModal from '@/views/componentsViews/notation/ProgressModal.vue'

export default {
  name: 'NotationStateSubview',
  components: {
    Alert,
    Section,
    Container,
    NotationCard,
    ProgressModal,
    Timeline,
    Btn,
  },
  props: {
    pageTitle: {
      type: String,
    },
    task: {
      type: Object,
    },
    essai: {
      type: Object,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    const statesUids = [
      'ENPREPARATION',
      'RESERVE',
      'ENCOURS',
      'ATTENTESYNCHRO',
      'PARTIELLEMENTREALISE',
      'REALISE',
    ]

    return {
      step: 0,
      states: [],
      notation: {},
      frames: [],
      reservation: {},
      reservations: [],
      reservationStatus: this.$store.state.app.reservationStatus,
      stades: this.$store.state.app.stades,
      studiedVariable: [],
      owner: false,
      statesUids,
      noOrder: false,
      fetched: false,
      editable: this.isEditable,
      progress: { total: 12, current: 0 },
      error: null,
    }
  },

  mounted() {
    this.getDataNotationState('root')
    this.emitter.on('notation-refresh', this.getDataNotationState)
  },

  methods: {
    async getDataNotationState() {
      try {
        this.progress.current = 0
        this.emitter.emit('progress-modal-data', this.progress)
        this.emitter.emit('progress-modal-open')

        if (this.$route.params.id) {
          this.progress.current = 1
          this.emitter.emit('progress-modal-progress', this.progress)

          // fetch de la notation pour vérifier qu'elle est bien paramétré
          const notationResponse = await this.fetchService.get(`essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation`)
          this.notation = notationResponse.data.pop()
          // si pas de d'odre ou pas de plan on coupe
          if (
            (!this.essai?.plan) || (
              this.notation.saisie_objet.uid !== 'MODALITE'
              && (
                this.notation.saisie_ordre === null
                || (this.notation.saisie_ordre.uid === 'MANUEL'
                  && this.notation.chemin_personnalise === null)
              )
            )) {
            this.noOrder = true
            this.emitter.emit('progress-modal-close')
            this.fetched = true
            return false
          }
          this.progress.current = 2
          this.emitter.emit('progress-modal-progress', this.progress)

          // Fetch les variables
          const variablesResponse = await this.fetchService.get(`essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation/${this.notation.id}/variable_etudiee`, { limit: 0 })
          this.studiedVariable = variablesResponse.data
          this.progress.current = 3
          this.emitter.emit('progress-modal-progress', this.progress)

          // Fetch des trames
          const tramesResponse = await this.fetchService.get(`essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation/${this.notation.id}/trames`, { limit: 0 })
          this.frames = tramesResponse.data
          this.frames = { ...this.frames, ...{ terrainIndex: 0 } }
          this.frames = { ...this.frames, ...{ laboIndex: 0 } }

          this.progress.current = 4
          this.emitter.emit('progress-modal-progress', this.progress)

          // Fetch des reservations
          const reservationResponse = await this.fetchService.get(`essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation/${this.notation.id}/reservation`, { limit: 0, sort: 'id.ASC' })
          this.reservations = reservationResponse.data
          if (this.reservations.length > 0) {
            this.reservation = this.reservations.pop()
          } else {
            this.reservation = {
              id: null,
              etat: {
                uid: 'ENPREPARATION',
                designation: 'En préparation',
              },
              utilisateur: this.$store.state.auth.user.data,
            }
          }
          this.progress.current = 5
          this.emitter.emit('progress-modal-progress', this.progress)

          this.notation.variables = this.studiedVariable
          this.reservation.essai = this.essai
          this.reservation.evenement = this.task
          this.reservation.evenement.designation = this.task.designation ?? 'Notation'
          this.reservation.notation = this.notation
          this.reservation.frames = this.frames

          // fetch des notes par paquet de 50 si il y en a
          let notes = []
          const totalNotesresponse = await this.fetchService.get(`essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation/${this.notation.id}/variable_etudiee/note`, { limit: 1 })
          if (totalNotesresponse.meta.pagination.count_over > 0) {
            const totalNotePage = Math.ceil(totalNotesresponse.meta.pagination.count_over / 50) + 1
            const PromiseArray = []

            for (let index = 1; index < totalNotePage; index += 1) {
              PromiseArray.push(this.getNotes(index).then((resp) => {
                this.progress.current += 1 / totalNotePage
                this.emitter.emit('progress-modal-progress', this.progress)
                return resp
              }))
            }
            const promiseAllReturn = await Promise.all(PromiseArray)

            this.progress.current += 1
            this.emitter.emit('progress-modal-progress', this.progress)

            promiseAllReturn.forEach((response) => {
              notes = notes.concat(response)
            })

            // dédoublonnage
            if (notes.length > 0) {
              notes = notes.filter((value, index, self) => index === self.findIndex((t) => (
                t.euid === value.euid
              )))
            }
          }

          this.reservation.notation.notes = notes
          this.reservation.notation.startingnote = notes
          this.progress.current += 1
          this.emitter.emit('progress-modal-progress', this.progress)

          this.states = []
          this.statesUids.forEach((element) => {
            const state = this.reservationStatus.find(
              (item) => item.uid === element,
            )
            if (state) {
              this.states.push(state.designation)
            }
          })

          this.step = this.statesUids.findIndex(
            (v) => v === this.reservation.etat.uid,
          )
          this.progress.current += 1
          this.emitter.emit('progress-modal-progress', this.progress)

          if (
            this.reservation?.utilisateur?.id
            === this.$store.state.auth.user.data.id
          ) {
            this.owner = true
            if (
              this.reservation.id !== null
              && (
                this.reservation.etat.uid === 'ENCOURS'
                || this.reservation.etat.uid === 'RESERVE'
              )
              && !this.notationService.getItem(
                'reservations',
                this.reservation.id,
              )
            ) {
              this.notationService.setItem(
                'reservations',
                this.reservation,
              )
            }
          }
          this.progress.current += 1
          this.emitter.emit('progress-modal-progress', this.progress)

          if (this.reservation?.id !== null && this.notationService.getItem('reservations', this.reservation.id)) {
            this.reservation = this.notationService.getItem('reservations', this.reservation.id)
          }
          this.progress.current += 1
          this.emitter.emit('progress-modal-progress', this.progress)

          if (this.reservation?.etat?.uid === 'ENPREPARATION') {
            this.editable = true
          } else {
            this.editable = false
          }

          this.fetched = true
          this.progress.current = 11
          this.emitter.emit('progress-modal-progress', this.progress)
        }

        this.emitter.emit('progress-modal-close')
        return true
      } catch (error) {
        this.emitter.emit('progress-modal-close')
        console.error(error)
        this.error = error
        this.fetched = true
        return true
      }
    },

    getNotes(page = 1) {
      return new Promise((resolve) => {
        this.fetchService
          .get(
            `essai/${this.$route.params.id}/evenement/${this.$route.params.tid}/fiche/notation/${this.notation.id}/variable_etudiee/note`,
            { limit: 50, page },
          )
          .then((responseNote) => {
            const notes = responseNote.data.map((note) => {
              let euid = ''
              let numeroechantillon = null
              let { valeurautre } = note

              if (note.microparcelle) {
                euid = `${note.microparcelle.id}-${note.variable.id}`
              }

              if (note.modalite) {
                euid = `${note.modalite}-${note.variable.id}`
              }

              if (note?.organe) {
                numeroechantillon = parseInt(note.numeroechantillon, 10)
                euid = `${euid}-${note.organe.id}-${numeroechantillon}`
              } else if (parseInt(note.numeroechantillon, 10) && note.variable?.saisie_niveau?.uid === 'ECHANTILLON') {
                numeroechantillon = parseInt(note.numeroechantillon, 10)
                euid = `${euid}-${parseInt(note.numeroechantillon, 10)}`
              }

              if (valeurautre && note.variable?.variable?.type?.uid === 'DATE') {
                let dateArray = []
                if (valeurautre.includes('-')) {
                  dateArray = valeurautre.split('-')
                } else {
                  dateArray = valeurautre.split('/')
                }
                valeurautre = `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`
              }

              return {
                id: note.id,
                euid,
                valeur: note.valeur,
                valeurautre,
                nonapplicable: note.nonapplicable,
                valeur_liste_id: note?.valeur_liste?.id,
                numeroechantillon,
                variable_id: note.variable.id,
                organe_id: note?.organe?.id,
                microparcelle_id: note?.microparcelle?.id,
                modalite: note.modalite,
                error: false,
              }
            })

            resolve(notes)
          })
      })
    },

    setLocalStorage(reservationId) {
      const rp = this.$route.params
      this.notationService.setCollectionFromDb(
        'essai',
        `essai/${this.$route.params.id}`,
        reservationId,
      )
      this.notationService.setCollectionFromDb(
        'variables',
        `essai/${rp.id}/evenement/${rp.tid}/fiche/notation/${this.notation.id}/variable_etudiee?limit=0`,
        reservationId,
      )
    },
  },
}
</script>
