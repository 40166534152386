<template>
  <div class="block-code">
    <div class="btn-wrapper">
      <Btn text="Copier"
      size="xs"
      @click="copy()" /></div>
    <pre><code ref="message">{{ code }}</code></pre>
  </div>
</template>

<script>
import Btn from '@/components/base/Btn.vue'

export default {
  name: 'BlockCode',
  components: {
    Btn,
  },
  props: {
    code: {
      type: String,
    },
  },
  data() {
    return {
      message: this.code,
    }
  },
  methods: {
    copy() {
      const storage = document.createElement('textarea')
      storage.value = this.$refs.message.innerHTML
      document.body.appendChild(storage)
      storage.select()
      storage.setSelectionRange(0, 9999999)
      document.execCommand('copy')
      document.body.removeChild(storage)
    },
  },
}
</script>

<style lang="scss" scoped>
.block-code {
  position: relative;
}
.btn-wrapper {
    position: absolute;
    top: 0;
    right: 0;
  }

pre {
  background-color: $color-gray-lightest;
  margin: 0;
  border: 1px solid $color-gray-lightest;
  padding: $gutter-half $gutter-half $gutter-half;
}

code {
  background-color: $color-gray-lightest;
  color: $color-gray-light;
}
</style>
